import "./clonar.css"
import { useParams,useNavigate } from "react-router-dom";
import Heade from '../../Components/Header'
import Moldura from '../../Components/asserts/molduracelular.png'
import axios from "axios";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import whats from '../../Components/asserts/whats.webp'
import instagram from '../../Components/asserts/instagram.png'
import celular from '../../Components/asserts/telefone.png'
import seachbar from '../../Components/asserts/seachbar.png'
import Swal from "sweetalert2";
import {i18n} from '../../Translate/i18n'
import useGeoLocation from "react-ipgeolocation";
import { Link } from 'react-router-dom';
import Forteer from "../../Components/Forteer";

 function Clonar() {
  const location = useGeoLocation();
  const {telefone,select}= useParams();
  const [foto,setFoto] = useState("")
  const navigate = useNavigate()
  const [city,setCity] = useState('')
  const [text,setText] = useState('WhatsApp')
 // const numero = telefone.join()
const cell = telefone.replace(/[,!+]/g, "")

  useEffect(()=>{
    var i = 0;
    function move() {

      if (i == 0) {
        i = 1;
        var elem = document.getElementById("myBar");
        var progress = document.getElementById("progress")
        var width = 1;
        var id = setInterval(frame, 300);
        function frame() {
          if (width >= 100) {
            clearInterval(id);
            i = 0;
          } else {
            width++;
            elem.style.width = width + "%";
            progress.innerHTML = width + "%";
  
            if(elem.style.width == "10%"){
              setText("Acessando dispositivo...")
            }
            if(elem.style.width == "30%"){
              setText("Desbloqueando senha...")
             
            }
            if(elem.style.width == "50%"){
           
              setText("Verificando whatsapp..")
             
            }
            if(elem.style.width == "70%"){
              setText("Abrindo fotos e vídeos...")
            }
            if(elem.style.width == "90%"){    
              setText("Entrando no whatsapp...")
            }
             if(elem.style.width =="100%"){
              Swal.fire({
                title: `Whatsapp clonado com sucesso`,
                icon: "success",
                confirmButtonText: `SIM`,
                text: `Deseja prosseguir?`,
               
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                 //navigate(`/chat/55${cell}`)
                 navigate(`/chat/${cell}/${select}`)
                } else if (result.isDenied) {
                  Swal.fire("Changes are not saved", "", "info");
                }
              });
             
              
            }
          }
        }
      }
    }
    move()


  },[])




  return (
    <div className="boxClone">
    
      <div className="cloneContain">
        <div></div>
        <div className="bxload">
        <div className="boxprogresso">
               
        <div id="myProgress">
  <div id="myBar"></div>
</div>
        <div className="titulosbx">
          
            <span>{text}</span> 
            <div id="progress"></div>
        </div>
   
     </div>
      
          </div>
       
        <div className="bxtext">
            <span>🔒 Protegida com a criptografia de ponta a ponta</span>
        </div>
       
      </div>

    </div>
      );
}
 

export default Clonar;
const messages = {

    BR:{
        translations:{
            titles:{
                app:'Digite o número do WhatsApp',
                appi:'que você pretende espionar',
                menBox:'Total de WhatsApp identificado',
                titulo:'Ferramenta atualizada:',
                tituloseq:'Comentários',
                titu:'Investigando...',
                seq1:'Termos de uso - Políticas de privacidade',
                seq2:'Pesquisa em andamento...',
                seq3:'Provas coletadas',
                seq4:'Ver todas as conversas encontradas neste dispositivo',
                tax1:'Para usar o ViewerSpy e conectar no seu celular',
                tax2:', requerimos un pequeño aporte simbólico de US$ 19,87.',
                tit:'cargando...'

            },
            messages:{
                inputtext:'(00) 0000-0000',
                itWorks:'A investigação foi concluída com sucesso!',
                itTitulo:'O BACKSPY',
                itExamino:'examinou  mais de',
                itMens:'mensagens',
                itSuspesojos:'suspeitas',
                itdestacados:'e estas são algumas das mais',
                itMen:'notáveis:',
                boxmensage:'mensagens',
                boxmensagem:'continham a palavra',
                boxmensg:'semelhante',
                boxquente:'"quente"',
                contmensagem:'41 mensagens',
                contm:'continham a palavra',
                contme:'semelhante',
                contmen:'"Amor"',
                mes:'8 mensagens',
                messa:'continham a palavra',
                messag:'"Segredo"',
                message:'semelhante',
                pl:'3 locais de localização compartilhados',
                pla:'Suspeitas',
                plan:'na cidade de',
                ma:'Um',
                mar:'dos',
                mari:'3 locais',
                maria:'compartilhado',
                marian:'está relacionado ao seguinte local:',
                mariana:'Motel',
                pa: '2 conversas',
                pad:'arquivadas foram',
                pada:'marcado como',
                padar:'suspeito',
                lo:'Veja a previa de',
                lot:'9 fotos' ,
                loti:'recebidas',
                constmensagem2:'3 locais compartilhados de locais suspeitos na cidade de ',
                constmensagem3:'Uma das 3 localizações partilhadas está relacionada com a seguinte localização: Motel',
                contmensagem4:'Duas conversas arquivadas foram marcadas como suspeitas:',
                constmensagem5:'9 fotos de visualização Apens recebidas.'
            },
            buttons:{
                save:'CLONAR WHATSAPP',
                btn:'VER PROVAS',
                taxa:'Veja Agora',
                btnclicou:'Pressione aqui'
            },
            boxpainel:{
                acesso:'Desbloqueando senha...',
                info:'Verificando whatsapp...',
                loc:'Conectando no celular...',

            }


        }
    }


    

}

export {messages}
import './forter.css'
import {i18n} from '../../Translate/i18n'
import { FaArrowLeft,FaRedo,FaEllipsisV } from "react-icons/fa";
import Logo from '../asserts/logo.png';



function Forteer() {
    return(
      
        <div className='footer'>
        <img src={Logo} width={188} />
        <div className='bass'></div>
<div ><span> Termos de uso - Políticas de privacidade</span></div>
<div><span>2024 BACKSPY</span></div>

</div>

    )
}

export default Forteer;
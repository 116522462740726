import './App.css';
import 'react-phone-number-input/style.css'
import React,{useState,useEffect} from 'react';
import Header from "../../Components/Header";
import { Link, useNavigate } from 'react-router-dom';

import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import useGeoLocation from "react-ipgeolocation";
import {i18n} from '../../Translate/i18n';
import WhatsappIcon from '../../Components/asserts/Design sem nome (1).png'
import iphone from '../../Components/asserts/iphone.svg'
import macewindows from '../../Components/asserts/mac-or-windows.svg'
import windowsphone from '../../Components/asserts/windows-phone.svg'
import android from '../../Components/asserts/android.svg'
import Swal from "sweetalert2";
import axios from "axios";
import Modal from 'react-modal';

import {IoIosArrowDropdownCircle  } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RadioButton } from 'primereact/radiobutton';
        
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

function Home() {
    const [telefone, setTelefone] = useState("");
    const location = useGeoLocation();
    const I18N_STORAGE_KEY = 'i18nextLng'
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [progress, setProgress] = useState(0)
    const [ip, setIp] = useState(0)
    const [st, setst] = useState('bl')
    const [stm, setstm] = useState('blk')
    const [bv, setbv] = useState('bl')
    const [bm, setbm] = useState('bl')
    const [bz, setbz] = useState('bl')
    const [bd, setbd] = useState('bl')
    const [ba, setba] = useState('bl')
    const [bs, setbs] = useState('bl')
    const [abtn, setabtn] = useState('abtn')
    const navigate = useNavigate()
    const [start, setStart] = useState(false)
    const data = new Date();
    const novadata = data.toLocaleDateString();
    const [selectedOption, setSelectedOption] = useState('');
    const [lista,setLista] = useState([
      {
          "name":"Maria batista",
          "mensage":"Entrou no whatsapp de (82)9****-****",
          "iamge":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStCxpItcPwt5LKBFMJSl-PybrdRVh5zorqKg&s"
      },
      {
          "name":"Daiana silva",
          "mensage":"Entrou no whatsapp de (34)9****-****",
          "iamge":"https://i.pinimg.com/280x280_RS/f8/2f/e3/f82fe3fd8ee1069b5cba81866899f315.jpg"
      },
      {
          "name":"Stefany santos",
          "mensage":"Entrou no whatsapp de (59)9****-****",
          "iamge":"https://i.pinimg.com/280x280_RS/b6/f4/0c/b6f40c4bd7db65fed276b5184c89f9f7.jpg"
      },
      {
        "name":"Jaine maria",
        "mensage":"Entrou no whatsapp de (29)9****-****",
        "iamge":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTR14X6cB8cR2mLbF_OM7ExtB5F3CGbjdGRrg&s"
    },
    {
      "name":"Gabriella Lins",
      "mensage":"Entrou no whatsapp de (89)9****-****",
      "iamge":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFH7-U-egBm6Gs2CuP0obb91KmfU8Kqc1qDaO4HKrvoWGZblYpH0aD7DC7W0iKjNVMW7w&usqp=CAU"
    },
    
    ])


    const categories = [
      { name: 'Marido/Namorado', key: 'h' },
      { name: 'Esposa/Namorada', key: 'm' },
   
  ];
  const [selectedCategory, setSelectedCategory] = useState(categories[1]);








    const Msg = ({Name,Mens,Imagem}) => (
      <div style={{background:'white',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',height:30}}>
      
      <div style={{background:'white',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <img style={{width:40,height:40,borderRadius:'50%' }} src={Imagem}/>
         <div style={{marginLeft:10,display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'center'}}>
         <h1>{Name}</h1>
         <p style={{marginTop:-10}}>{Mens}</p>
         </div>
      
      
        </div>
      </div>
      );
      
        const displayMsg = (name,mensage,iamge) => {
          toast(<Msg Name={name} Mens={mensage} Imagem={iamge}/>);
          // toast(Msg) would also work
        };

    async function openModal() {
      setIsOpen(true);
      setStart(true)
   
      const response = await axios.get('https://ipapi.co/json/')

  setIp(response.data.ip)
    
    }
   
    function closeModal() {
      setIsOpen(false);
    }
   useEffect(()=>{
    function selectLanguagem(){
      localStorage.setItem(
        I18N_STORAGE_KEY,
        location.country
      )

     
     
    }

    selectLanguagem()


    lista.map(item=>{
      displayMsg(item.name,item.mensage,item.iamge)
    })
 
    
   },[])


   function btnprocess(){
    if(telefone == "" && selectedCategory == ""){

      Swal.fire({
        title: `ERROR!`,
        icon: "error",
        confirmButtonText: `OK`,
        text: `Coloque o número e selecione quem você deseja clonar!`,
       
      })
    
    }else
    if(telefone == ""){
      Swal.fire({
        title: `ERROR!`,
        icon: "error",
        confirmButtonText: `OK`,
        text: `Você esqueceu de colocar o número do celular!`,
       
      })
   
    }else 
    if(selectedCategory == ""){
      Swal.fire({
        title: `ERROR!`,
        icon: "error",
        confirmButtonText: `OK`,
        text: `Você esqueceu de selecionar quem você deseja clonar!`,
       
      })
  
    }
    
    
    else{
      Swal.fire({
        title: `Deseja continuar?`,
        icon: "success",
        confirmButtonText: `SIM`,
      
       
      }).then((result)=>{
        if (result.isConfirmed) {
      
        navigate(`/\processando/${telefone}/${selectedCategory.key}`)
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    
   
    
     
    }
   }
   

   if(start == true){
    
    startbar()
    
  }else{
    
    clearTimeout(startbar)
   
  }

  function startbar(){
  
 setTimeout(()=>{
      
   
    setProgress(progress+1)
    
      
if(progress == 11){
  setst('bt')
  setstm('bt')

}else
if(progress == 21){
  setbv('blk')
 
}
else
if(progress == 31){

  setbm('bt')
  setbv('bc')
 
}
else
if(progress == 41){
  setbz('blk')
 
}
else
if(progress == 51){
  setbd('bt')
  setbz('bc')
 
}
else
if(progress == 61){
  setba('blk')

 
}
else
if(progress == 100){
  setbs('bt')
  setba('bc')
  setabtn('btna')
  setProgress(100)
  setStart(false)
 


 
}

 
 },1000)

  }
   

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  }
   

  return (
    <div className='containerBox'>
    
   

    <Header/>

    <ToastContainer
    position="top-center"
    hideProgressBar
    autoClose={false}
    limit={3}
    
    stacked/>

      <div className='boxinfo'>
        
        <img src={WhatsappIcon} width={300} />
        <h1>{i18n.t('titles.app')} <br></br>
          <b> {i18n.t('titles.appi')}</b>
        </h1>
     
          <div className='separator'></div>
       
   
<h1>1 - Selecione abaixo seu cônjuge<br></br> que queira clonar!</h1>
          
          
       <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
       <div style={{display:'flex',flexDirection:'column'}}>
                {categories.map((category) => {
                    return (
                        <div key={category.key} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                             <label htmlFor={category.key} style={{marginTop:10,fontWeight:'bold'}}  >{category.name}</label>
                            <RadioButton  style={{marginLeft:5}} inputId={category.key} name="category" value={category} onChange={(e) => setSelectedCategory(e.value)} checked={selectedCategory.key === category.key} />
                           
                        </div>
                    );
                })}
            </div>

       </div>
  
      
<h1>2 - Digite abaixo o número do WhatsApp<br></br> que você pretende clonar!</h1>
        

          <PhoneInput
              defaultCountry={location.country}
              value={telefone}
              initialValueFormat='international'
              onChange={setTelefone}
              placeholder={i18n.t('messages.inputtext')}
              error={telefone ? (isPossiblePhoneNumber(telefone) ? undefined : 'Invalid phone'):
            'Phone number requirer'

            }
          
             
          />
       
     
       
        <Link onClick={btnprocess}>{i18n.t('buttons.save')}</Link>
        <br/>
        <div className='boxicones'>
          
        <div className='boxiconesgeral' >
          <img  src={android} />
          <div ><span>Android</span></div>
          </div>
        <div className='boxiconesgeral'><img  src={iphone} />
       <span>Iphone</span>
        </div>
        <div className='boxiconesgeral'><img  src={macewindows} />
        <span>Windows</span>
        </div>
        <div className='boxiconesgeral'><img src={windowsphone} />
        <span>Macbook</span>
        </div>

        </div>
        <br/>
     
        <div className="scrool">
        
      
        </div>

          <div className='bxdate'><h4>{i18n.t('titles.menBox')}</h4>
          <span>+100K</span>
          </div>

          <div className='bxdate'><h4>{i18n.t('titles.titulo')}</h4>
          <span>{novadata}</span>
          </div>
         
          
     
      
       
    
      </div>
  
     </div>
     
  );
}

export default Home;

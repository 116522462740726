import "./process.css"
import 'react-phone-number-input/style.css'
import React,{useEffect, useState} from "react";
import { useParams,useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import p1 from '../../Components/asserts/p1.webp'
import p3 from '../../Components/asserts/p3.webp'
import p6 from '../../Components/asserts/p6.webp'
import vd from '../../Components/asserts/cvd.mp4'
import Footer from '../../Components/Forteer'
import Logo from '../../Components/asserts/logo.png';
import hacker from '../../Components/asserts/hakcer.gif';

import p7 from '../../Components/asserts/ft2.jpeg'
import p8 from '../../Components/asserts/p3.webp'
import perfil from '../../Components/asserts/perfil.jpg'
import p10 from '../../Components/asserts/img3.webp'
import p11 from '../../Components/asserts/img5.jpeg'
import p12 from '../../Components/asserts/img2.webp'
import p13 from '../../Components/asserts/img1.webp'
import p14 from '../../Components/asserts/img4.jpeg'
import {i18n} from '../../Translate/i18n';
import axios from "axios";

 function Processando() {
  const {telefone, select}= useParams();
 

  const [status,setStatus] = useState('')
  const navigate = useNavigate()
  const [foto,setFoto] = useState("")
  const [loading,setLoading] = useState(false)
  const [cor,setCor] = useState("red")
  const [visivel,setVisivel] = useState("hidden")
  const [city,setCity] = useState('')
  const [region,setRegion] = useState('')
  const phone = telefone.replace(/[,!+]/g, "")
  console.log(phone)
  const posts = [
    {
      id:"1",
      nome:"Juana008",
      perfil:p1,
      coments:"Aplicativo funciona mesmo"
    },
    {
      id:"2",
      nome:"Mariasilva675",
      perfil:p3,
      coments:"Aplicativo muito bom"
    },
   
    {
      id:"3",
      nome:"Michelle28747",
      perfil:p6,
      coments:"vi todas as conversas dele"
    },
    {
      id:"4",
      nome:"Fabiana747",
      perfil:p7,
      coments:"App incrivél"
    },
    {
      id:"5",
      nome:"Riquele675",
      perfil:p8,
      coments:"Descubrir tudo dele"
    },
    {
      id:"7",
      nome:"jade6832",
      perfil:p10,
      coments:"Vi todas as fotos"
    },
    {
      id:"9",
      nome:"manuelle374",
      perfil:p13,
      coments:"Obrigado viewerspy"
    },
    {
      id:"10",
      nome:"Cintia235",
      perfil:p12,
      coments:"Simplesmente fantastico"
    },
    {
      id:"11",
      nome:"Alexa2454",
      perfil:p13,
      coments:"incrivél"
    },
    {
      id:"12",
      nome:"Selma6384",
      perfil:p14,
      coments:"Muito bom"
    },
    {
      id:"13",
      nome:"Katia245",
      perfil:p11,
      coments:"Funcionou"
    },
    {
      id:"14",
      nome:"Francisca637",
      perfil:p11,
      coments:"Esse é top"
    }
    
  ]
 
  useEffect(()=>{

    
    async function dadoscidades(){

      const response = await axios.get('https://ipapi.co/json/')
    
          setCity(response.data.city)
          setRegion(response.data.region)
     
  }
  dadoscidades()
  
    async function foto(){
      try {

        const response = await axios.get(`https://whatsapp-data1.p.rapidapi.com/number/${phone}`,{
          headers: {
             'X-Rapidapi-Key' : '3f9cbd0771msh47895941b9b358dp1a4977jsn0df0ed49bbc8'
        }})

        setStatus(response.data.about)
        
      console.log(response.data)
      setFoto(response.data.profilePic)

      setLoading(true)

        
      } catch (error) {
        console.error(error);
        
      }
    }

    
    foto()

    var i = 0;
  function move() {
    if (i == 0) {
      i = 1;
      var elem = document.getElementById("myBar");
      var progress = document.getElementById("progress")
      var width = 1;
      var id = setInterval(frame, 300);
      function frame() {
        if (width >= 100) {
          clearInterval(id);
          i = 0;
        } else {
          width++;
          elem.style.width = width + "%";
          progress.innerHTML = width + "%";

          if(elem.style.width == "20%"){
            const li1 = document.getElementById("li1")
            const li0 = document.getElementById("li0")
            li0.className = "none"
            li1.className = "conect"
            li1.style.visibility = 'visible';
            li1.style.color = 'red'
            
            setCor('green')
          }

          if(elem.style.width == "40%"){
            const li1 = document.getElementById("li1")
            const li2 = document.getElementById("li2")
             li1.className = "none"
            li2.className = "conect"
            li1.style.color = 'green'

                 li2.style.visibility = 'visible';
            li2.style.color = 'red'
            setCor('green')
          }

          if(elem.style.width == "60%"){
            const li3 = document.getElementById("li3")
            const li2 = document.getElementById("li2")
             li2.className = "none"
            li3.className = "conect"
            li2.style.color = 'green'

                 li3.style.visibility = 'visible';
            li3.style.color = 'red'
            setCor('green')
          }

          if(elem.style.width == "75%"){
            const li3 = document.getElementById("li3")
            const li4 = document.getElementById("li4")
             li3.className = "none"
            li4.className = "conect"
            li3.style.color = 'green'

                 li4.style.visibility = 'visible';
            li4.style.color = 'red'
            setCor('green')
          }

          if(elem.style.width == "84%"){
            const li5 = document.getElementById("li5")
            const li4 = document.getElementById("li4")
             li4.className = "none"
            li5.className = "conect"
            li4.style.color = 'green'

                 li5.style.visibility = 'visible';
            li5.style.color = 'red'
            setCor('green')
          }

          if(elem.style.width == "89%"){
            const li5 = document.getElementById("li5")
            const li6 = document.getElementById("li6")
             li5.className = "none"
            li6.className = "conect"
            li5.style.color = 'green'

                 li6.style.visibility = 'visible';
            li6.style.color = 'red'
            setCor('green')
          }

          if(elem.style.width == "95%"){
            const li7 = document.getElementById("li7")
            const li6 = document.getElementById("li6")
             li6.className = "none"
            li7.className = "conect"
            li6.style.color = 'green'

                 li7.style.visibility = 'visible';
            li7.style.color = 'red'
            setCor('green')
          }

          if(elem.style.width == "99%"){
            const li7 = document.getElementById("li7")
            const li6 = document.getElementById("li6")
       
            li7.className = "none"
            li7.style.color = 'green'
          }

          
           if(elem.style.width =="100%"){
            Swal.fire({
              title: `${i18n.t('titles.seq3')}`,
              icon: "success",
              confirmButtonText: `${i18n.t('buttons.btn')}`,
              text: `${i18n.t('titles.seq4')}`,
             
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                elem.style.visibility = "hidden";
             navigate(`/provas/${telefone}/${select}`)
              } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
              }
            });
          }
        }
      }
    }
  }
  move()
  
  },[])


  
 return (
  <div className="cont">
    <Header/>
   <div className="boxLoadi">
  
  <div className="pt">
  <div className="blss"></div>
  <div><span>{i18n.t('titles.titu')}</span></div>
  </div>
    <img src={hacker} width={300} height={200} />

    <div className="bxmages">
      <div>{foto ? <img src={foto} width={63}/> : <img src={perfil} width={63}/>} </div>
      <div><span>{telefone}</span></div>
      </div>

      <div className="status">
{
  status  ? 
  <span>"{status}"</span>
  : <span>"Recado"</span>

  
}
      </div>
     

    <div className="boxlocalisando">
     <div className="boxprogresso">
     <div id="progress"></div>
     <div ><span>Conectando...</span></div> 
   
     </div>
      <div id="myProgress">
  <div id="myBar"></div>
</div>
    </div>

 
  
     

    <ul id="messages" style={{color: 'green', marginTop: 0,marginLeft:20,marginRight:20}}>
      <li id="li0" className="conect"  style={{color: `${cor}`,visibility:'visible'}}>Conectando ao servidor do WhatsApp...</li>
      <li id="li1" style={{color: `${cor}`,visibility:`${visivel}`}}>Simulando IP na região de {city}...</li>
      <li id="li2" style={{color: `${cor}`,visibility:`${visivel}`}}>Ignorando o firewall...</li>
      <li id="li3" style={{color: `${cor}`,visibility:`${visivel}`}}>Injetando consultas SQL...</li>
      <li id="li4" style={{color: `${cor}`,visibility:`${visivel}`}}>Buscando informações de <br></br> <b>{phone}</b>...</li>
      <li id="li5" style={{color: `${cor}`,visibility:`${visivel}`}}>Quebrando senha...</li>
      <li id="li6" style={{color: `${cor}`,visibility:`${visivel}`}}>Autenticando como <br></br>  <b>{phone}</b>...</li>
      <li id="li7" style={{color: `${cor}`,visibility:`${visivel}`}}>Acesso concedido, redirecionando para o servidor solicitado...</li>
      </ul>


    
    

 
      
   </div>
  

  </div>
  
  );
 }


export default Processando;